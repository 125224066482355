import Stepper from 'components/Stepper'
import DateSelect from 'components/DateSelect'
import VerifyClient from 'components/VerifyClient'
import Confirmation from 'components/Confirmation'
import ErrorBoundary from 'components/ErrorBoundary'
import { useAtomValue } from 'jotai/utils'
import { stepperAtom } from 'atoms'
import logo from './amr-logo.png'

export default function Example() {
  const currentStep = useAtomValue(stepperAtom)
  return (
    <div className="flex w-full px-4 py-16 bg-gradient-to-b from-avis-700 h-100 sm:h-screen min-h-screen flex-col">
      <div className="w-full max-w-5xl mx-auto flex flex-col">
        <img src={logo} alt='logo' className='my-5 m-auto' />
        <Stepper />
        <ErrorBoundary>
          {currentStep === 1 && <VerifyClient />}
          {currentStep === 2 && <DateSelect />}
          {currentStep === 3 && <Confirmation />}
        </ErrorBoundary>
      </div>
    </div>
  )
}