import { BadgeCheckIcon} from '@heroicons/react/solid'
import { useAtomValue } from 'jotai/utils'
import { format } from 'date-fns'
import { chosenDateAtom } from 'atoms'
import {sk} from "date-fns/locale";
import map from "map.jpg";

export default function Confirmation() {
  const chosenDate = useAtomValue(chosenDateAtom)  

  function getDateFormatted(date, dateFormat) {
    return date && format(Date.parse(date.replace(' ','T')), dateFormat, { locale: sk })
  }

  return (
      <>
        {chosenDate &&
            <div className='flex w-full sm:justify-between gap-2 mt-5 '>
                <div className='bg-white rounded-lg w-full sm:px-10 sm:py-6 p-5 flex flex-col gap-3 text-sm text-gray-500'>
                    <div className='flex items-center justify-between'>
                        <h1 className='text-2xl font-bold leading-9 text-zinc-900'>Ďakujeme !</h1>
                        <BadgeCheckIcon className="h-12 w-12 text-green-400" aria-hidden="true" />
                    </div>
                    <p>Dátum <span className='font-bold text-zinc-900'>{getDateFormatted(chosenDate, 'dd.MM.yyyy HH:mm')}</span> bol úspešne vybraný.</p>
                    <p>Vozidlo si vyzdvihnite vo vybranom termíne v United Mobility Centre.</p>
                    <a href='https://www.google.com/maps/place/AVIS+Van+Rental/@48.1894723,17.1867893,169m/data=!3m1!1e3!4m5!3m4!1s0x476c8faa0868f6d7:0x194dcaef73e03f18!8m2!3d48.1898031!4d17.1871131'
                    target="_blank"
                      rel="noreferrer"
                       className='flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-avis-500 hover:bg-avis-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avis-500 sm:text-sm'>
                        Nájdete nás tu
                    </a>
                    <a href='https://www.google.com/maps/place/AVIS+Van+Rental/@48.1894723,17.1867893,169m/data=!3m1!1e3!4m5!3m4!1s0x476c8faa0868f6d7:0x194dcaef73e03f18!8m2!3d48.1898031!4d17.1871131'
                    target="_blank"
                      className='border-2 border-avis-500 rounded'
                      rel="noreferrer">
                      <img src={map} alt="AVIS map" />
                    </a>
                </div>                
            </div>
            }
      </>
  )
}