import { useEffect, useState } from 'react'
import { Tab, RadioGroup, Dialog } from '@headlessui/react'
import { ArrowCircleRightIcon, BanIcon } from '@heroicons/react/solid'
import { format } from 'date-fns'
import { sk } from 'date-fns/locale'
import { useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'

import { chosenDateAtom, isErrorAtom, stepperAtom, userDataAtom } from 'atoms'
import { useAtomValue } from 'jotai/utils'

import API from '../api';

export default function DateSelect() {

  const setCurrentStep = useUpdateAtom(stepperAtom);

  const userData = useAtomValue(userDataAtom);
  const isError = useAtomValue(isErrorAtom)
  const [timeData, setTimeData] = useState(false);
  const [chosenDate, setChosenDate] = useAtom(chosenDateAtom);

  const [isOpen, setIsOpen] = useState(false);
  const [isPOSTError, setIsPOSTError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [freeSlotsError, setFreeSlotsError] = useState(null);

  function getDateFormatted(date, dateFormat) {
    return date && format(Date.parse(date.replace(' ','T')), dateFormat, { locale: sk })
  }

  async function uploadTimeData() {
    setLoading(true)
    try {
      userData && await API.post('/integration/crm/confirmDate', {
        orderNumber: userData.orderNumber,
        brand: process.env.REACT_APP_BRAND,
        date: getDateFormatted(chosenDate, 'yyyy-MM-dd'),
        time: getDateFormatted(chosenDate, 'HH:mm')
      })
      setLoading(false)
      setCurrentStep(3)
    } catch (e) {
      setIsPOSTError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    async function fetchTimeData() {
      try {
        const response = await API.get('/integration/getFreeSlots?orderNumber=' + userData.orderNumber + '&branchId=' + process.env.REACT_APP_BRANCH_ID)
        if (response.status === 200) {
          setTimeData(response.data);
        }
      } catch (e) {
        setFreeSlotsError(e.response?.data?.error)
      }
    }
    fetchTimeData()
  }, [setTimeData, userData.orderNumber])

  return (
    <>
      {timeData && timeData.daySlots && (
        <div className='flex flex-col sm:flex-row gap-4 mt-5 '>
          <Tab.Group defaultIndex={0}>
            <div className='w-full'>
              <div className='rounded-lg bg-zinc-900 shadow-inner text-white w-full sm:px-5 px-1'>
                <Tab.List className='flex justify-around sm:gap-4 gap-1'>
                  {timeData.daySlots.map(day => (
                    <Tab key={day.date} className={({ selected }) => `${selected ? 'bg-white text-zinc-900 font-semibold sm:w-[85px] w-[65px]' : 'sm:w-[70px] w-[50px]'} rounded-lg py-5 text-center`}>
                      {
                        getDateFormatted(day.date, window.screen.width > 748 ? 'EEEE dd.MM' : 'dd.MM').charAt(0).toUpperCase() +
                        getDateFormatted(day.date, window.screen.width > 748 ? 'EEEE dd.MM' : 'dd.MM').slice(1) //TODO simply
                      }
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels>
                {timeData.daySlots.map(day => (
                  <Tab.Panel key={day.date}>
                    {day.slots.length > 1 ?
                      <div className={`${day.slots.length > 24 ? 'sm:grid-cols-9' : 'sm:grid-cols-7'} rounded-lg mt-4 grid grid-cols-4 gap-2 bg-white p-3 w-100`}>
                        {day.slots.map(time => (
                          <RadioGroup value={chosenDate} onChange={setChosenDate} key={`${day.date} ${time.slice(0, -3)}`}>
                            <RadioGroup.Option
                              key={`${day.date} ${time.slice(0, -3)}`}
                              value={`${day.date} ${time.slice(0, -3)}`}
                              className={({ active, checked }) =>
                                `${checked ? ' bg-avis-700 text-white ' : 'hover:bg-gray-200'}
                                                            rounded-md px-2 py-4 font-medium text-center
                                                            hover:cursor-pointer`}
                            >
                              <RadioGroup.Label as='p'>
                                {time.slice(0, -3)}
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          </RadioGroup>
                        ))}
                      </div>
                      :
                      <div className='rounded-lg mt-3 bg-white p-5 w-full'>
                        <p>Bohužiaľ, v tento deň nie je voľný žiaden čas.</p>
                      </div>
                    }
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
          <div className='flex sm:flex-col gap-4 justify-between sm:justify-start'>
            <div className='rounded-lg h-[88px] min-w-[190px] p-3 bg-zinc-900 text-white flex items-center'>
              {chosenDate ?
                <div>
                  <p>Zvolený dátum a čas</p>
                  <div className='flex sm:gap-3 gap-4'>
                    <p className='font-bold text-3xl'>{getDateFormatted(chosenDate, 'HH:mm')}</p>
                    <div className='text-sm	text-zinc-300'>
                      <p>{getDateFormatted(chosenDate, 'd MMM yyyy')}</p>
                      <p className='sm:block hidden'>{getDateFormatted(chosenDate, 'eeee')}</p>
                    </div>
                  </div>
                </div>
                :
                <p>Vyberte voľný časový slot</p>}
            </div>
            {chosenDate &&
              <button disabled={!chosenDate} onClick={() => { setIsOpen(true); console.log(chosenDate) }}
                className='sm:w-full w-1/3 rounded-lg flex justify-center items-center gap-2 bg-avis-700 p-3 border-zinc-900 border-4'>
                <div className='flex gap-3 text-white items-center'>
                  <p className='hidden sm:block'>Pokračovať</p>
                  <ArrowCircleRightIcon className='h-7 w-7 text-white' aria-hidden='true' />
                </div>
              </button>
            }
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}
              className='fixed z-10 inset-0 flex items-center'>
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />

              <div className='my-auto mx-auto bg-white p-8 w-fit z-20 rounded-lg'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-zinc-900'
                >
                  Potvrdenie zvoleného dátumu
                </Dialog.Title>
                <div className='mt-2 text-sm text-gray-500'>
                  <p>Vami zvolený dátum je <span className='font-bold text-zinc-900'>{getDateFormatted(chosenDate, 'dd.MM.yyyy HH:mm')}</span>.</p>
                </div>
                <div className='mt-4 flex gap-1'>
                  <button
                    type='button'
                    className='inline-flex justify-center w-2/5 px-4 py-2 text-sm text-white font-medium bg-zinc-900 border rounded-md hover:bg-zinc-700'
                    onClick={() => setIsOpen(false)}
                  >
                    Zrušiť
                  </button>
                  <button
                    type='button'
                    disabled={loading}
                    className='inline-flex justify-center w-3/5 px-4 py-2 text-sm text-white font-medium bg-avis-700 border rounded-md hover:bg-avis-500'
                    onClick={uploadTimeData}
                  >
                    {loading &&
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    }
                    Súhlasím
                  </button>
                </div>
              </div>
            </Dialog>
            <Dialog open={isPOSTError || isError} onClose={() => setIsPOSTError(false)}
              className='fixed z-10 inset-0 flex items-center'>
              <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />
              <div
                className='flex flex-col items-center gap-3 my-auto mx-auto bg-white p-6 w-fit z-20 rounded-lg br text-center'>
                <BanIcon className="h-12 w-12 text-avis-700" aria-hidden="true" />
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-zinc-900'
                >
                  Chyba
                </Dialog.Title>
                <p className='text-sm text-gray-500'>Skúste to znova, ak sa vám to nepodarí, kontaktujte podporu.</p>
                <button type="button"
                  className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-avis-500 hover:bg-avis-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avis-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsPOSTError(false)}>
                  Dobré
                </button>
              </div>
            </Dialog>
          </div>
        </div>
      )}
      {freeSlotsError &&
        <div className='flex w-full sm:justify-between gap-2 mt-5 '>
          <div className='bg-white rounded-lg w-full sm:px-10 sm:py-6 p-5 flex flex-col gap-3 text-sm text-gray-500'>
            <div className='flex flex-col items-center gap-3 my-auto mx-auto bg-white w-fit z-20 rounded-lg br text-center'>
              <BanIcon className="h-12 w-12 text-avis-700" aria-hidden="true" />
              <p className='text-lg font-medium leading-6 text-zinc-900'>Chyba</p>
              <p className='text-sm text-gray-500'>{freeSlotsError}</p>
            </div>
          </div>
        </div>
      }
    </>
  )
}